import React from 'react';
import axios from 'axios';
import App from './App';
import config from './config';

axios.defaults.baseURL = config.baseApiAddress;

const Root = () => <App />;

export default Root;
