import { withStyles, InputBase } from '@material-ui/core';

const StyledSelect = withStyles((theme) => ({
  root: {
    '& svg': {
      fill:
        theme?.palette?.type === 'dark'
          ? 'rgba(235, 242, 255, 1)'
          : theme?.fdProColors?.electricBlue[700],
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    fontSize: 12,
    fontWeight: 500,
    color:
      theme?.palette?.type === 'dark'
        ? 'rgba(235, 242, 255, 1)'
        : theme?.fdProColors?.electricBlue[700],
    padding: '4px 36px 4px 12px',
    backgroundColor:
      theme?.palette?.type === 'dark'
        ? 'rgba(51, 51, 255, 1)'
        : 'rgba(235, 242, 255, 1)',
  },
}))(InputBase);

export default StyledSelect;
