import React, { useEffect } from 'react';
import {
  ThemeProvider,
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import { useAppTheme, setShowNav } from '@fifthdomain/fe-shared';
import { AuthProvider } from '@fifthdomain/auth';
import Sidebar from './pages/Sidebar';
import GraphQlClient from './shared/GraphQlClient';

const generateClassName = createGenerateClassName({
  productionPrefix: 'sidebar',
  seed: 'sidebar',
});

const App = () => {
  const theme = useAppTheme();

  useEffect(() => {
    setShowNav(true);
  });

  return (
    <ThemeProvider theme={theme}>
      <GraphQlClient>
        <BrowserRouter>
          <StylesProvider generateClassName={generateClassName}>
            <AuthProvider>
              <Sidebar />
            </AuthProvider>
          </StylesProvider>
        </BrowserRouter>
      </GraphQlClient>
    </ThemeProvider>
  );
};

export default App;
