import { isBefore, isAfter } from 'date-fns';

export const getCompetitionStatus = (
  startDateTime,
  endDateTime,
  serverTime,
) => {
  if (
    !startDateTime ||
    isAfter(new Date(startDateTime || undefined), new Date(serverTime))
  ) {
    return 'NOT_STARTED';
  }
  if (isBefore(new Date(endDateTime || undefined), new Date(serverTime))) {
    return 'ENDED';
  }
  if (isBefore(new Date(startDateTime || undefined), new Date(serverTime))) {
    return 'STARTED';
  }
  return 'ENDED';
};
